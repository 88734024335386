import { inject } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivateFn, Router, UrlTree } from '@angular/router'
import { JwtHelper } from '@core/classes/jwt.helper'

export const canActivateAuth = (loggedInRequired: boolean, navigatePath: string = '/'): CanActivateFn => {
	return (route: ActivatedRouteSnapshot): boolean | UrlTree => {
		const jwtHelper: JwtHelper = new JwtHelper()

		if (loggedInRequired) {
			if (!jwtHelper.tokenIsSet() || !jwtHelper.refreshTokenIsValid()) {
				jwtHelper.deleteTokens()
				const router = inject(Router)
				router.navigate([ navigatePath ]).then()
				return false
			}
		} else {
			if (jwtHelper.tokenIsSet()) {
				const router = inject(Router)
				router.navigate([ navigatePath ]).then()
				return false
			}
		}

		return true
	}
}
